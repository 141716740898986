






























































import Vue from 'vue';
import Component from 'vue-class-component';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import { IIntegration } from '@/store/types/IIntegrations';
import { Prop } from 'vue-property-decorator';

@Component
export default class IntegrationExportHistoryTable extends Vue {
  @Prop() integration!: IIntegration;
  async resetExportedRecordsForFileStorageIntegration(
    integrationId: number,
    exportFormatIntegrationid: number,
  ) {
    await UserSettingsModule.resetExportedRecordsForFileStorageIntegration(
      { integrationId, exportFormatIntegrationid },
    );
  }
  async resetAllExportedRecordsForIntegration(integrationId: number) {
    await UserSettingsModule.resetAllExportedRecordsForIntegration(
      integrationId,
    );
  }
}
