var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"mt-4 mb-6"},[_vm._v("\n    "+_vm._s(_vm.$t('TL_INTEGRATIONS_FORM_CONNECTED_INTEGRATIONS'))+"\n  ")]),_vm._v(" "),_c('exportoptionsmodal',{attrs:{"visible":_vm.exportoptionsmodalvisible},on:{"update:visible":function($event){_vm.exportoptionsmodalvisible=$event}}}),_vm._v(" "),_c('b-table',{attrs:{"hover":"","fields":_vm.fields,"items":_vm.integrationsConnected},scopedSlots:_vm._u([{key:"cell(image)",fn:function(data){return [_c('img',{attrs:{"width":"32","height":"32","src":require(("@/assets/images/integrations/" + (data.item.IntegrationName) + ".128x128.png")),"alt":""}})]}},{key:"cell(autoexport)",fn:function(data){return [(data.item.SupportsAutoExport)?_c('b-form-checkbox',{attrs:{"checked":data.item.IntegrationExportSettings.AutoExport,"name":"check-button","switch":""},on:{"change":function($event){return _vm.toggleIntegrationAutoSync(data.item.IntegrationID)}}},[_c('b',[_vm._v("\n          "+_vm._s(data.item.IntegrationExportSettings.AutoExport
              ? _vm.$t('TL_SHARED_OPTIONS_ON')
              : _vm.$t('TL_SHARED_OPTIONS_OFF'))+"\n        ")])]):_vm._e()]}},{key:"cell(connection)",fn:function(data){return [_c('b-button',{attrs:{"data-cy":'disconnect-' + data.item.IntegrationFriendlyName,"squared":"","variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.disconnectIntegration(data.item.IntegrationID)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'power-off']}}),_vm._v("\n        "+_vm._s(_vm.$t('TL_INTEGRATIONS_FORM_DISCONNECT'))+"\n      ")],1)]}},{key:"cell(exportsettings)",fn:function(data){return [_c('b-button',{attrs:{"squared":"","variant":"outline-dark","size":"sm"},on:{"click":function($event){return _vm.openExportSettings(data.item.IntegrationID)}}},[_vm._v(_vm._s(_vm.$t('TL_CONNECTED_INTEGRATIONS_OPEN_SETTINGS')))])]}},{key:"cell(exportcount)",fn:function(data){return [(
          data.item.ExportedRecordCount > 0 &&
          data.item.ExportFileTypeStats.length > 0
        )?_c('div',[_c('integrationexporthistorytable',{attrs:{"integration":data.item}})],1):_c('div',{attrs:{"data-cy":'exportedrecordcount-' + data.item.IntegrationFriendlyName}},[_vm._v("\n        "+_vm._s(_vm._f("number")(data.item.ExportedRecordCount,'0,0'))+"\n        "),(data.item.ExportedRecordCount > 0)?_c('b-button',{staticClass:"ml-4",attrs:{"squared":"","variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.resetAllExportedRecordsForIntegration(
              data.item.IntegrationID
            )}}},[_vm._v("\n          "+_vm._s(_vm.$t('TL_INTEGRATIONS_FORM_RESET'))+"\n        ")]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }