



































































































import Vue from 'vue';
import Component from 'vue-class-component';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import integrationexporthistorytable from './integrationexporthistorytable.vue';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import exportoptionsmodal from '@/components/modals/ExportOptionsModal.vue';
import i18n from '@/i18n';

@Component({
  components: {
    integrationexporthistorytable,
    exportoptionsmodal,
  },
})
export default class ConnectedIntegrationsTable extends Vue {
  exportoptionsmodalvisible = false;
  fields = [
    { key: 'image', label: '' },
    {
      key: 'IntegrationFriendlyName',
      label: i18n.t('TL_SHARED_INTEGRATION'),
    },
    {
      key: 'autoexport',
      label: i18n.t('TL_INTEGRATIONS_FORM_AUTO_EXPORT'),
    },
    {
      key: 'connection',
      label: i18n.t('TL_INTEGRATIONS_FORM_CONNECTION'),
    },
    {
      key: 'exportsettings',
      label: i18n.t('TL_INTEGRATIONS_FORM_EXPORT_SETTINGS'),
    },
    {
      key: 'exportcount',
      label: i18n.t('TL_INTEGRATIONS_FORM_EXPORTED_RECORDS'),
    },
  ];

  openExportSettings(integrationId: number) {
    ExportUiStateModule.selectIntegration(integrationId);
    this.exportoptionsmodalvisible = true;
  }

  get integrationHasBeenSelected() {
    return ExportUiStateModule.integrationHasBeenSelected;
  }
  get integrationsConnected() {
    return UserSettingsModule.connectedIntegrations.map((item) => ({
      ...item,
    }));
  }

  toggleIntegrationAutoSync(integrationID: number) {
    UserSettingsModule.toggleAutoSync(integrationID);
  }

  async resetAllExportedRecordsForIntegration(integrationId: number) {
    await UserSettingsModule.resetAllExportedRecordsForIntegration(
      integrationId,
    );
  }

  disconnectIntegration(integrationID: number) {
    UserSettingsModule.revokeIntegration(integrationID);
    //we redirect back to integrations so that the connected message is dismissed if you just connected
    this.$router.push('/settings/integrations/').catch((error) => {
      if (error.name != 'NavigationDuplicated') {
        throw error;
      }
    });
  }
}
