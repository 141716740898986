



































import { Component, Vue } from 'vue-property-decorator';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import integrationsselector from '@/components/integrationsselector.vue';
import integrationconnectedmodal from '@/components/modals/IntegrationConnectedModal.vue';
import connectedintegrationstable from './components/connectedintegrationstable.vue';

@Component({
  components: {
    integrationsselector,
    integrationconnectedmodal,
    connectedintegrationstable,
  },
})
export default class IntegrationsPage extends Vue {
  connectIntegration(integrationID: number) {
    UserSettingsModule.authenticateIntegration(integrationID);
  }

  get integrationsUnconnected() {
    return UserSettingsModule.unconnectedIntegrations;
  }
  get integrationsConnected() {
    return UserSettingsModule.connectedIntegrations;
  }

  get integrationConnectedModalVisible() {
    return UserSettingsModule.integrationJustConnected;
  }
  set integrationConnectedModalVisible(value: boolean) {
    UserSettingsModule.integrationConnected(0);
  }

  get justConnectedToIntegration() {
    return UserSettingsModule.justConnectedToIntegration;
  }
}
